import apiService from './apiService';

export const getCandidatesService = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `/admin/candidates?${queryString}`;

  const response = await apiService.get(url);
  return response.data;
};

export const getCandidatesAuditService = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `/admin/audit/candidates?${queryString}`;

  const response = await apiService.get(url);
  return response.data;
};

export const getSpecificCandidatesAuditService = async (candidateId) => {
  const url = `/admin/audit/candidates/${candidateId}`;

  const response = await apiService.get(url);
  return response.data;
};

export const getCandidateResumeLogsService = async (candidateId) => {
  const url = `/admin/resume-log/${candidateId}`;

  const response = await apiService.get(url);
  return response.data;
};

export const getSingleCandidateService = async (candidateId) => {
  const url = `/admin/candidates/${candidateId}`;

  const response = await apiService.get(url);
  return response.data;
};

export const getCandidateJobApplicationsService = async (candidateId, pageNumber = 0) => {
  const url = `/admin/candidates/${candidateId}/job-applications?pageNumber=${pageNumber}`;

  const response = await apiService.get(url);
  return response.data;
};

export const createCandidateService = async (candidate) => {
  const url = `/admin/candidates`;

  const response = await apiService.post(url, candidate);
  return response.data;
};

export const editCandidateService = async (candidateId, candidate) => {
  const url = `/admin/candidates/${candidateId}`;

  const response = await apiService.patch(url, candidate);
  return response.data;
};

export const deleteCandidateService = async (candidateId) => {
  const url = `/admin/candidates/${candidateId}`;

  const response = await apiService.delete(url);
  return response.data;
};

export const deleteSuggestedCandidateProfileService = async (suggestionId) => {
  const url = `/admin/profile-suggestion/${suggestionId}`;

  const response = await apiService.delete(url);
  return response.data;
};

export const createProfileSuggestionService = async (payload) => {
  const url = `/admin/profile-suggestion`;

  const response = await apiService.post(url, payload);
  return response.data;
};

export const onlyJobLocationsService = async () => {
  const url = `admin/job-locations-only`;
  const response = await apiService.get(url);
  return response.data;
}


export const exportCandidateListService = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `/admin/candidates/export?${queryString}`;
  try {
    const response = await apiService.get(url, { responseType: 'blob' });
    // Create a blob object from the response data
    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    // Create a URL for the blob object
    const downloadUrl = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = downloadUrl;

    let currentTimestamp = new Date().getTime();
    const fileName = `Candidate_List_${currentTimestamp}.xlsx`;

    link.download = fileName;
    // Trigger the download
    document.body.appendChild(link);
    link.click();
    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);
    return { success: true };
  } catch (error) {
    return { success: false, error: error.message };
  }
};
