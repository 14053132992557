import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextInputComponent from '../components/TextFieldComponent';
import ButtonComponent from '../components/ButtonComponent';
import TimePickerComponent from '../components/TimePickerComponent'
import CheckboxComponent from '../components/CheckBoxComponent'
import DropdownComponent from '../components/DropDownComponent';
import constants from '../helpers/constants.json';
import { clearEditedJobFlag, clearAddedJobFlag, createNewJob, editJobRecord, getJobRolesList } from '../redux/actions/jobActions';
import { formatted12hTime, convertTimeStringToDate } from '../helpers/formattedDateTime';
import CreatableDropdownComponent from '../components/CreatableDropDown';


const validationSchema = Yup.object().shape({
    jobTitle: Yup.object().required('Job Title is required'),
    jobDescription: Yup.string().required('Job Description is required'),
    jobLocation: Yup.string().required('Job Location is required'),
    salaryLowerRange: Yup.number().required('Salary Lower Range is required'),
    salaryUpperRange: Yup.number().required('Salary Upper Range is required'),
    requiredEnglishProficiency: Yup.object().required('Required English Proficiency is required'),
});


const JobPostForm = ({ employerId, jobDetail, onClose, jobPostQueryParams }) => {
    const dispatch = useDispatch();

    const [rolesArray, setRolesArray] = useState([]);
    const isEditJobSuccess = useSelector((state) => state.job.editJobSuccess);

    const isNewJobPostCreated = useSelector((state) => state.job.newJobCreated);
    const jobRoles = useSelector((state) => state.job.jobRoles?.data) || [];

    const { englishProficiency } = constants;
    const englishProfObject = englishProficiency.find(englishProf => englishProf?.id === jobDetail?.requiredEnglishProficiency)
    const jobTitleObject = jobDetail?.jobTitle ? {
        "name": jobDetail.jobTitle,
        "id": jobDetail.jobTitle,
        "description": jobDetail.jobDescription
    } : null;

    const updatedJobDetails = {
        ...jobDetail,
        requiredEnglishProficiency: englishProfObject || null,
        jobTitle: jobTitleObject || null
    };


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            jobTitle: updatedJobDetails ? updatedJobDetails.jobTitle : null,
            jobDescription: jobDetail ? jobDetail.jobDescription : '',
            jobLocation: jobDetail ? jobDetail.jobLocation : '',
            salaryLowerRange: jobDetail ? jobDetail.salaryLowerRange : '',
            salaryUpperRange: jobDetail ? jobDetail.salaryUpperRange : '',
            shiftStartTime: jobDetail ? convertTimeStringToDate(jobDetail.shiftStartTime) : null,
            shiftEndTime: jobDetail ? convertTimeStringToDate(jobDetail.shiftEndTime) : null,
            callingHoursStart: jobDetail ? convertTimeStringToDate(jobDetail.callingHoursStart) : null,
            callingHoursEnd: jobDetail ? convertTimeStringToDate(jobDetail.callingHoursEnd) : null,
            provideAccomodation: jobDetail ? jobDetail.provideAccomodation : false,
            requiredEnglishProficiency: updatedJobDetails ? updatedJobDetails.requiredEnglishProficiency : '',
            additionalPerks: jobDetail ? jobDetail.additionalPerks : '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const formattedValues = {
                ...values,
                jobTitle: values.jobTitle.name,
                requiredEnglishProficiency: values.requiredEnglishProficiency?.id,
                shiftStartTime: formatted12hTime(values.shiftStartTime),
                shiftEndTime: formatted12hTime(values.shiftEndTime),
                callingHoursStart: formatted12hTime(values.callingHoursStart),
                callingHoursEnd: formatted12hTime(values.callingHoursEnd),
                employerId,
                salaryLowerRange: parseInt(values.salaryLowerRange, 10),
                salaryUpperRange: parseInt(values.salaryUpperRange, 10),
            };

            if (jobDetail?.jobPostId) {
                await dispatch(editJobRecord(jobDetail.jobPostId, formattedValues));
            } else {
                await dispatch(createNewJob(formattedValues));
            }
        },
    });

    // Effect to fetch job roles list once when the component mounts
    useEffect(() => {
        dispatch(getJobRolesList({ approved: true }));
    }, [dispatch]);

    useEffect(() => {
        if (jobRoles) {
            let tempArray = jobRoles?.map((obj) => ({ "name": obj.name, "id": obj.name, "description": obj.description }));
            if (jobDetail?.jobTitle && !jobRoles?.find(e => e.name == jobDetail?.jobTitle)) {
                tempArray.unshift({ "name": jobDetail?.jobTitle, "id": jobDetail?.jobTitle, "description": jobDetail?.jobDescription });
            }
            setRolesArray(tempArray);
        }
    }, [jobRoles, jobDetail?.jobTitle]);

    useEffect(() => {
        if (isEditJobSuccess) {
            formik.resetForm()
            dispatch(clearEditedJobFlag());
            onClose();
        }
    }, [dispatch, formik, isEditJobSuccess, onClose]);

    // Effect to clear form once job is created 
    useEffect(() => {
        if (isNewJobPostCreated) {
            formik.resetForm();
            dispatch(clearAddedJobFlag());
            // dispatch(getJobsList(jobPostQueryParams));
        }
    }, [formik, dispatch, isNewJobPostCreated]);

    useEffect(() => {
        const selectedJobTitle = formik.values.jobTitle;
        // Check if selectedJobTitle has changed
        if (selectedJobTitle && selectedJobTitle.description) {
            formik.setFieldValue("jobDescription", selectedJobTitle.description);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.jobTitle]);


    return (
        <form onSubmit={formik.handleSubmit}>

            <CreatableDropdownComponent
                label="Job Title *"
                field={{
                    ...formik.getFieldProps("jobTitle"),
                    onChange: formik.handleChange("jobTitle"),
                }}
                form={formik}
                options={rolesArray}
                fullWidth
                style={{ marginBottom: "16px" }}
                value={formik.values.jobTitle}
            />

            <TextInputComponent
                label="Job Description *"
                type="text"
                multiline
                rows={4}
                field={{
                    ...formik.getFieldProps('jobDescription'),
                    onChange: formik.handleChange('jobDescription'),
                }}
                form={formik}
                fullWidth
                style={{ marginBottom: '16px' }}
            />

            <TextInputComponent
                label="Job Location *"
                type="text"
                field={{
                    ...formik.getFieldProps('jobLocation'),
                    onChange: formik.handleChange('jobLocation'),
                }}
                form={formik}
                fullWidth
                style={{ marginBottom: '16px' }}
            />

            <TextInputComponent
                label="Salary Lower Range *"
                type="tel"
                inputmode="numeric"
                pattern="[0-9\s]{13,19}"
                field={{
                    ...formik.getFieldProps('salaryLowerRange'),
                    onChange: formik.handleChange('salaryLowerRange'),
                }}
                form={formik}
                fullWidth
                style={{ marginBottom: '16px' }}
            />

            <TextInputComponent
                label="Salary Upper Range *"
                type="tel"
                inputmode="numeric"
                pattern="[0-9\s]{13,19}"
                field={{
                    ...formik.getFieldProps('salaryUpperRange'),
                    onChange: formik.handleChange('salaryUpperRange'),
                }}
                form={formik}
                fullWidth
                style={{ marginBottom: '16px' }}
            />

            <div style={{ marginBottom: '16px' }}>
                <TimePickerComponent
                    label="Shift Start Time"
                    field={{
                        ...formik.getFieldProps('shiftStartTime'),
                        onChange: formik.handleChange('shiftStartTime'),
                    }}
                    form={formik}
                    fullWidth
                    style={{ marginBottom: '16px' }}
                />
            </div>

            <div style={{ marginBottom: '16px' }}>
                <TimePickerComponent
                    label="Shift End Time"
                    field={{
                        ...formik.getFieldProps('shiftEndTime'),
                        onChange: formik.handleChange('shiftEndTime'),
                    }}
                    form={formik}
                    fullWidth
                    style={{ marginBottom: '16px' }}
                />
            </div>

            <div style={{ marginBottom: '16px' }}>
                <TimePickerComponent
                    label="Calling Hours Start"
                    field={{
                        ...formik.getFieldProps('callingHoursStart'),
                        onChange: formik.handleChange('callingHoursStart'),
                    }}
                    form={formik}
                    fullWidth
                    style={{ marginBottom: '16px' }}
                />
            </div>

            <div style={{ marginBottom: '16px' }}>
                <TimePickerComponent
                    label="Calling Hours End"
                    field={{
                        ...formik.getFieldProps('callingHoursEnd'),
                        onChange: formik.handleChange('callingHoursEnd'),
                    }}
                    form={formik}
                    fullWidth
                    style={{ marginBottom: '16px' }}
                />
            </div>

            <CheckboxComponent
                label="Provide Accommodation"
                field={{
                    ...formik.getFieldProps('provideAccomodation'),
                    checked: formik.values.provideAccomodation,
                    onChange: formik.handleChange('provideAccomodation'),
                }}
                form={formik}
                style={{ marginBottom: '16px' }}
            />

            <DropdownComponent
                label="English Proficiency *"
                field={{
                    ...formik.getFieldProps("requiredEnglishProficiency"),
                    onChange: formik.handleChange("requiredEnglishProficiency"),
                }}
                form={formik}
                options={englishProficiency}
                fullWidth
                style={{ marginBottom: "16px" }}
                value={formik.values.requiredEnglishProficiency}
            />

            <TextInputComponent
                label="Additional Perks"
                type="text"
                field={{
                    ...formik.getFieldProps('additionalPerks'),
                    onChange: formik.handleChange('additionalPerks'),
                }}
                form={formik}
                fullWidth
                style={{ marginBottom: '16px' }}
            />

            <ButtonComponent
                type="submit"
                label={formik.isSubmitting ? 'Submitting...' : 'Submit'}
                fullWidth
                disabled={formik.isSubmitting}
            />
        </form>
    );
};

export default JobPostForm;
