import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextFieldComponent from "../components/TextFieldComponent";
import DropdownComponent from "../components/DropDownComponent";
import ButtonComponent from "../components/ButtonComponent";
import constants from "../helpers/constants.json";
import { clearAddedCandidateFlag, clearEditedCandidateFlag, createNewCandidate, editCandidateRecord, getCandidatesList } from "../redux/actions/candidateActions";
import { getJobRolesList } from '../redux/actions/jobActions';
import { formatDDMMYYYYDate, formatYYYYMMDDDate } from "../helpers/formattedDateTime";
import moment from "moment";
import CreatableDropdownComponent from "../components/CreatableDropDown";
import CheckboxComponent from "../components/CheckBoxComponent";
import { isDateValid, parseMobileNumber } from "../helpers/utility";
import { isAppUserExist } from "../redux/service/userService";


const CandidateForm = ({ candidateDetail, onClose, candidateQueryParams, resumeParsedData }) => {

  const dispatch = useDispatch();
  const [rolesArray, setRolesArray] = useState([]);
  const jobRoles = useSelector((state) => state.job.jobRoles?.data);
  const isEditCandidateSuccess = useSelector((state) => state.candidate.editCandidateSuccess);
  const [isCandidateExist, setIsCandidateExist] = useState(false);

  const isNewCandidateCreated = useSelector(
    (state) => state.candidate.newCandidateCreated
  );

  const { gender, englishProficiency, experienceLevel, educationList } = constants;

  const genderObject = gender.find(gender => gender?.id === candidateDetail?.gender);
  const experienceObject = experienceLevel.find(experience => experience?.id === candidateDetail?.experienceLevel)
  const englishProfObject = englishProficiency.find(englishProf => englishProf?.id === candidateDetail?.englishProficiency)
  const highestEducationObject = educationList.find(education => education?.id === candidateDetail?.highestEducation)
  const preferredRole1Object = candidateDetail?.preferredRole1 ? { "name": candidateDetail.preferredRole1, "id": candidateDetail?.preferredRole1 } : null;
  const preferredRole2Object = candidateDetail?.preferredRole2 ? { "name": candidateDetail.preferredRole2, "id": candidateDetail?.preferredRole2 } : null;
  const preferredRole3Object = candidateDetail?.preferredRole3 ? { "name": candidateDetail.preferredRole3, "id": candidateDetail?.preferredRole3 } : null;

  const rmList = candidateDetail?.remarksList;
  // get most recent remark; assuming remarksList is sorted by create date in descending order
  const currentRemarkObj = (rmList && rmList?.length != 0) ? rmList[0] : null;

  const updatedCandidate = {
    ...candidateDetail,
    gender: genderObject || null,
    experienceLevel: experienceObject || null,
    englishProficiency: englishProfObject || null,
    highestEducation: highestEducationObject || null,
    preferredRole1: preferredRole1Object || null,
    preferredRole2: preferredRole2Object || null,
    preferredRole3: preferredRole3Object || null,
  };

  const getValidationSchema = Yup.lazy((values) => {
    let baseSchema = {
      name: Yup.string().required("Name is required"),
      mobileNumber: Yup.string()
        .matches(/^\d{10}$/, "Mobile Number must be exactly 10 digits")
        .required("Mobile Number is required"),
      preferredRole1: Yup.object().required("Preferred Role is required"),
      description: Yup.string().nullable().max(2048, "Number of characters should be less than 2048"),
      remarks: Yup.string().nullable().max(2048, "Number of characters should be less than 2048"),
      experiences: Yup.array().when('experienceLevel', {
        is: (expLevel) => (expLevel?.id == "EXPERIENCED"),
        then: () => Yup.array().of(
          Yup.object().shape({
            companyName: Yup.string().required("Company Name is required"),
            jobTitle: Yup.string().required("Job Title is required")
          })
        ),
        otherwise: () => Yup.array().nullable()
      })
    };

    if (candidateDetail) {
      baseSchema = {
        ...baseSchema,
        dob: Yup.date().nullable().test(
          "DOB",
          "Candidate must be 18 years old",
          value => {
            return value ? moment().diff(moment(value), 'years') >= 18 : true;
          }
        ),
      };
    } else {

      if (values.isNonAppUser) {
        baseSchema = {
          ...baseSchema,
          dob: Yup.date().required("Date of Birth is required").test(
            "DOB",
            "Candidate must be 18 years old",
            value => {
              return moment().diff(moment(value), 'years') >= 18;
            }
          ),
          education: Yup.object().required("Education is required"),
          institutionName: Yup.string().required("Institution Name is required"),
          courseName: Yup.string().required("Course Name is required"),
          startYear: Yup.number().when('education', {
            is: (education) => (education?.id === 'SSC_OR_BELOW' || education?.id === 'HSC'),
            then: () => Yup.number().nullable(),
            otherwise: () => Yup.number().required("Start Year is required")
          }),
          endYear: Yup.number().required("End Year is required"),
          englishProficiency: Yup.object().required("English Proficiency is required"),
          currentLocation: Yup.string().required("Current Location is required"),
          jobSearchLocation: Yup.string().required("Job Search Location is required"),
          experienceLevel: Yup.object().required("Experience Level is required"),
        };
      } else {
        baseSchema = {
          ...baseSchema,
          dob: Yup.date().nullable().test(
            "DOB",
            "Candidate must be 18 years old",
            value => {
              return value ? moment().diff(moment(value), 'years') >= 18 : true;
            }
          ),
        };
      }
    }

    return Yup.object().shape(baseSchema);
  });

  const fieldLabels = {
    name: "Name",
    dob: "Date of Birth",
    mobileNumber: "Mobile Number",
    gender: "Gender",
    description: "Description",
    education: "Education",
    institutionName: "Name of School",
    courseName: "Board",
    startYear: "Start Year",
    endYear: "End Year",
    englishProficiency: "English Proficiency",
    preferredRole1: "Preferred Role 1",
    preferredRole2: "Preferred Role 2",
    preferredRole3: "Preferred Role 3",
    currentLocation: "Current Location",
    jobSearchLocation: "Job Search Location",
    remarks: "New Remarks",
    experienceLevel: "Experience Level",
    companyName: "Company Name",
    jobTitle: "Job Title",
    salary: "Salary",
    startDate: "Start Date",
    endDate: "End Date"
  }

  const getFieldLabel = (fieldName) => {
    let fieldLabel = fieldLabels[fieldName];
    if (fieldName == "institutionName") {
      fieldLabel = (formik.values.education?.id === 'SSC_OR_BELOW' || formik.values.education?.id === 'HSC')
        ? 'Name of School'
        : 'Name of Institute'
    }

    if (fieldName == "courseName") {
      fieldLabel = (formik.values.education?.id === 'SSC_OR_BELOW' || formik.values.education?.id === 'HSC')
        ? 'Board'
        : 'Course Name'
    }

    if (candidateDetail) {
      switch (fieldName) {
        case 'name':
        case 'mobileNumber':
        case 'preferredRole1':
          fieldLabel = fieldLabel + " *";
          break;
        default:
          break;
      }
    } else {

      if (formik.values.isNonAppUser) {
        switch (fieldName) {
          case 'name':
          case 'mobileNumber':
          case 'preferredRole1':
          case 'dob':
          case 'education':
          case 'institutionName':
          case 'courseName':
          case 'startYear':
          case 'endYear':
          case 'englishProficiency':
          case 'currentLocation':
          case 'jobSearchLocation':
          case 'experienceLevel':
            fieldLabel = fieldLabel + " *";
            break;
          default:
            break;
        }
      } else {
        switch (fieldName) {
          case 'name':
          case 'mobileNumber':
          case 'preferredRole1':
            fieldLabel = fieldLabel + " *";
            break;
          default:
            break;
        }
      }
    }

    return fieldLabel;
  }

  const getExpFieldLabel = (fieldName, index) => {
    let fieldLabel = fieldLabels[fieldName];
    fieldLabel = `Experience ${index + 1}: ${fieldLabel}`;

    if (fieldName == "companyName" || fieldName == "jobTitle") {
      fieldLabel = fieldLabel + " *";
    }

    return fieldLabel;
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: candidateDetail ? candidateDetail.name : '',
      mobileNumber: candidateDetail ? parseMobileNumber(candidateDetail.mobileNumber) : '',
      gender: updatedCandidate ? updatedCandidate.gender : null,
      dob: candidateDetail && candidateDetail.dob && isDateValid(candidateDetail.dob) ? new Date(candidateDetail.dob) : null,
      description: candidateDetail ? candidateDetail.description : '',
      education: updatedCandidate ? updatedCandidate.highestEducation : '',
      institutionName: candidateDetail ? candidateDetail?.education?.institutionName : '',
      courseName: candidateDetail ? candidateDetail?.education?.courseName : '',
      startYear: candidateDetail ? candidateDetail?.education?.startYear : '',
      endYear: candidateDetail ? candidateDetail?.education?.endYear : '',
      englishProficiency: updatedCandidate ? updatedCandidate.englishProficiency : '',
      preferredRole1: updatedCandidate ? updatedCandidate.preferredRole1 : '',
      preferredRole2: updatedCandidate ? updatedCandidate.preferredRole2 : '',
      preferredRole3: updatedCandidate ? updatedCandidate.preferredRole3 : '',
      currentLocation: candidateDetail ? candidateDetail.location : '',
      jobSearchLocation: candidateDetail ? candidateDetail.jobLocation : '',
      remarks: '',
      isNonAppUser: candidateDetail ? (candidateDetail.nonAppUser != undefined ? !candidateDetail.nonAppUser : false) : true,
      experienceLevel: updatedCandidate ? updatedCandidate.experienceLevel : '',
      experiences: candidateDetail && candidateDetail.experienceLevel === 'EXPERIENCED'
        && candidateDetail.experience.length
        ? candidateDetail.experience.map(experience => ({
          id: experience.id,
          companyName: experience.companyName || "",
          jobTitle: experience.jobTitle || "",
          salary: experience.salary || "",
          startDate: experience.startDate && isDateValid(experience.startDate) ? new Date(experience.startDate) : null,
          endDate: experience.endDate && isDateValid(experience.endDate) ? new Date(experience.endDate) : null,
          createDate: experience.createDate ? new Date(experience.createDate) : null,
          updateDate: experience.updateDate ? new Date(experience.updateDate) : null,
          shouldDelete: experience.shouldDelete
        }))
        : [
          {
            companyName: "",
            jobTitle: "",
            salary: "",
            startDate: null,
            endDate: null,
          },
        ],
    },
    validationSchema: getValidationSchema,
    onSubmit: async (values) => {
      const formattedValues = {
        ...values,
        dob: values.dob ? formatYYYYMMDDDate(values.dob) : null,
        education: values.education?.id,
        experienceLevel: values.experienceLevel?.id,
        englishProficiency: values.englishProficiency?.id,
        gender: values.gender?.id,
        preferredRole1: values.preferredRole1?.name || null,
        preferredRole2: values.preferredRole2?.name || null,
        preferredRole3: values.preferredRole3?.name || null,
        startYear: (values.education ? (values.education?.id === 'HSC' || values.education?.id === 'SSC_OR_BELOW') ? "" : parseInt(values.startYear, 10) : null),
        endYear: values.endYear ? parseInt(values.endYear, 10) : null,
        isNonAppUser: !values.isNonAppUser,
        experiences: values.experienceLevel?.id === "EXPERIENCED" ? (values.experiences || []).filter(experience => experience.companyName).map((experience) => ({
          ...experience,
          startDate: experience.startDate ? formatYYYYMMDDDate(experience.startDate) : null,
          endDate: experience.endDate ? formatYYYYMMDDDate(experience.endDate) : null,
          salary: parseInt(experience.salary, 10),
        })) : [],
      };
      if (candidateDetail?.candidateId) {
        await dispatch(editCandidateRecord(candidateDetail.candidateId, formattedValues));
      } else {
        await dispatch(createNewCandidate(formattedValues));
      }
    },
  });

  // Effect to fetch job roles list once when the component mounts
  useEffect(() => {
    dispatch(getJobRolesList({ approved: true }));
  }, [dispatch]);

  useEffect(() => {
    if (jobRoles) {
      // if given roles are not in our remote job roles then first add it to the array
      let tempArray = jobRoles?.map((obj) => ({ "name": obj.name, "id": obj.name }));
      let tempSelectedRoles = [];
      if (candidateDetail?.preferredRole1 && !jobRoles?.find(e => e.name == candidateDetail?.preferredRole1)) {
        tempSelectedRoles.push({ "name": candidateDetail?.preferredRole1, "id": candidateDetail?.preferredRole1 });
      }
      if (candidateDetail?.preferredRole2 && !jobRoles?.find(e => e.name == candidateDetail?.preferredRole2)) {
        tempSelectedRoles.push({ "name": candidateDetail?.preferredRole2, "id": candidateDetail?.preferredRole2 });
      }
      if (candidateDetail?.preferredRole3 && !jobRoles?.find(e => e.name == candidateDetail?.preferredRole3)) {
        tempSelectedRoles.push({ "name": candidateDetail?.preferredRole3, "id": candidateDetail?.preferredRole3 });
      }

      tempArray.unshift(...tempSelectedRoles);

      setRolesArray(tempArray);
    }
  }, [jobRoles, candidateDetail?.preferredRole1, candidateDetail?.preferredRole2, candidateDetail?.preferredRole3])


  useEffect(() => {
    if (isNewCandidateCreated) {
      formik.resetForm()
      dispatch(clearAddedCandidateFlag());
      dispatch(getCandidatesList(candidateQueryParams));
    }

  }, [dispatch, formik, isNewCandidateCreated, candidateQueryParams]);


  useEffect(() => {
    if (isEditCandidateSuccess) {
      formik.resetForm()
      dispatch(clearEditedCandidateFlag());
      onClose();
    }
  }, [dispatch, formik, isEditCandidateSuccess, onClose]);

  const handleAddExperience = () => {
    if (formik.values.experienceLevel !== "Fresher") {
      formik.setFieldValue("experiences", [
        ...formik.values.experiences,
        {
          companyName: "",
          jobTitle: "",
          salary: "",
          startDate: "",
          endDate: "",
        },
      ]);
    }
  };

  const handleRemoveExperience = (experience, index) => {
    let newExperiences = [];
    if (experience && experience.id) {
      newExperiences = formik.values.experiences.map((experience, i) => {
        if (i === index) {
          return {
            ...experience,
            shouldDelete: true,
          };
        }
        return experience;
      });
    }
    else {
      newExperiences = [...formik.values.experiences];
      newExperiences.splice(index, 1);
    }

    formik.setFieldValue("experiences", newExperiences);
  };


  const isExperienceValid = (experience) => {
    return (
      !experience.shouldDelete &&
      experience.companyName !== null &&
      experience.jobTitle !== null &&
      experience.salary !== null &&
      experience.startDate !== null &&
      experience.endDate !== null
    );
  };

  const checkIfAppUserExist = (e) => {
    let mobileNumber = e?.target?.value;
    if (mobileNumber && mobileNumber.length >= 10) {
      isAppUserExist(mobileNumber).then((data) => {
        if (data?.data == null) {
          setIsCandidateExist(false);
        } else {
          setIsCandidateExist(true);
        }
      });
    } else {
      setIsCandidateExist(false);
    }
  }

  return (
    <form onSubmit={formik.handleSubmit}>


      <CheckboxComponent
        label="App User?"
        field={{
          ...formik.getFieldProps('isNonAppUser'),
          onChange: () => formik.handleChange('isNonAppUser'),
        }}
        form={formik}
        style={{ marginBottom: '16px' }}
      />


      <TextFieldComponent
        label={getFieldLabel("name")}
        type="text"
        field={{
          ...formik.getFieldProps("name"),
          onChange: formik.handleChange("name"),
        }}
        form={formik}
        fullWidth
        style={{ marginBottom: "16px" }}
      />

      <div style={{ marginBottom: '16px' }}>
        <TextFieldComponent
          label={getFieldLabel("dob")}
          type="date"
          field={{
            ...formik.getFieldProps("dob"),
            onChange: formik.handleChange("dob"),
          }}
          form={formik}
          fullWidth
          style={{ marginBottom: "16px" }}
        />
      </div>


      <TextFieldComponent
        label={getFieldLabel("mobileNumber")}
        type="tel"
        inputmode="numeric"
        pattern="[0-9\s]{13,19}"
        maxLength={10}
        minLength={10}
        disabled={candidateDetail ? (candidateDetail?.candidateId ? true : false) : false}
        field={{
          ...formik.getFieldProps("mobileNumber"),
          onChange: formik.handleChange("mobileNumber"),
        }}
        form={formik}
        fullWidth
        style={{ marginBottom: '16px' }}
        onBlur={candidateDetail ? null : checkIfAppUserExist}
        error={isCandidateExist ? true : null}
        helperText={isCandidateExist ? "User with this mobile number exists" : null}
      />

      <DropdownComponent
        label={getFieldLabel("gender")}
        field={{
          ...formik.getFieldProps("gender"),
          onChange: formik.handleChange("gender"),
        }}
        form={formik}
        options={gender}
        fullWidth
        style={{ marginBottom: "16px" }}
        selectStyle={{
          menuPortal: base => ({ ...base, zIndex: 9999 })
        }}
      />

      <TextFieldComponent
        label={getFieldLabel("description")}
        type="text"
        multiline={true}
        rows={4}
        field={{
          ...formik.getFieldProps("description"),
          onChange: formik.handleChange("description"),
        }}
        form={formik}
        fullWidth
        style={{ marginBottom: "16px" }}
      />

      <DropdownComponent
        label={getFieldLabel("education")}
        type="text"
        field={{
          ...formik.getFieldProps("education"),
          onChange: formik.handleChange("education"),
        }}
        form={formik}
        options={educationList}
        fullWidth
        style={{ marginBottom: "16px" }}
        selectStyle={{
          menuPortal: base => ({ ...base, zIndex: 9999 })
        }}
      />

      <TextFieldComponent
        label={getFieldLabel('institutionName')}
        type="text"
        field={{
          ...formik.getFieldProps("institutionName"),
          onChange: formik.handleChange("institutionName"),
        }}
        form={formik}
        fullWidth
        style={{ marginBottom: "16px" }}
      />

      <TextFieldComponent
        label={getFieldLabel('courseName')}
        type="text"
        field={{
          ...formik.getFieldProps("courseName"),
          onChange: formik.handleChange("courseName"),
        }}
        form={formik}
        fullWidth
        style={{ marginBottom: "16px" }}
      />


      {(formik.values.education && formik.values.education?.id !== 'SSC_OR_BELOW' && formik.values.education?.id !== 'HSC') &&
        <TextFieldComponent
          label={getFieldLabel("startYear")}
          type="tel"
          inputmode="numeric"
          pattern="[0-9\s]{13,19}"
          maxLength={4}
          field={{
            ...formik.getFieldProps("startYear"),
            onChange: formik.handleChange("startYear"),
          }}
          form={formik}
          fullWidth
          style={{ marginBottom: "16px" }}
        />
      }

      <TextFieldComponent
        label={getFieldLabel("endYear")}
        type="tel"
        inputmode="numeric"
        pattern="[0-9\s]{13,19}"
        maxLength={4}
        field={{
          ...formik.getFieldProps("endYear"),
          onChange: formik.handleChange("endYear"),
        }}
        form={formik}
        fullWidth
        style={{ marginBottom: "16px" }}
      />

      <DropdownComponent
        label={getFieldLabel("englishProficiency")}
        field={{
          ...formik.getFieldProps("englishProficiency"),
          onChange: formik.handleChange("englishProficiency"),
        }}
        form={formik}
        options={englishProficiency}
        fullWidth
        style={{ marginBottom: "16px" }}
        selectStyle={{
          menuPortal: base => ({ ...base, zIndex: 9999 })
        }}
      />

      <CreatableDropdownComponent
        label={getFieldLabel("preferredRole1")}
        field={{
          ...formik.getFieldProps("preferredRole1"),
          onChange: formik.handleChange("preferredRole1"),
        }}
        form={formik}
        options={rolesArray}
        fullWidth
        style={{ marginBottom: "16px" }}
        selectStyle={{
          menuPortal: base => ({ ...base, zIndex: 9999 })
        }}
      />

      <CreatableDropdownComponent
        label={getFieldLabel("preferredRole2")}
        field={{
          ...formik.getFieldProps("preferredRole2"),
          onChange: formik.handleChange("preferredRole2"),
        }}
        form={formik}
        options={rolesArray}
        fullWidth
        style={{ marginBottom: "16px" }}
        selectStyle={{
          menuPortal: base => ({ ...base, zIndex: 9999 })
        }}
      />

      <CreatableDropdownComponent
        label={getFieldLabel("preferredRole3")}
        field={{
          ...formik.getFieldProps("preferredRole3"),
          onChange: formik.handleChange("preferredRole3"),
        }}
        form={formik}
        options={rolesArray}
        fullWidth
        style={{ marginBottom: "16px" }}
        selectStyle={{
          menuPortal: base => ({ ...base, zIndex: 9999 })
        }}
      />

      <TextFieldComponent
        label={getFieldLabel("currentLocation")}
        type="text"
        field={{
          ...formik.getFieldProps("currentLocation"),
          onChange: formik.handleChange("currentLocation"),
        }}
        form={formik}
        fullWidth
        style={{ marginBottom: "16px" }}
      />

      <TextFieldComponent
        label={getFieldLabel("jobSearchLocation")}
        type="text"
        field={{
          ...formik.getFieldProps("jobSearchLocation"),
          onChange: formik.handleChange("jobSearchLocation"),
        }}
        form={formik}
        fullWidth
        style={{ marginBottom: "16px" }}
      />

      {currentRemarkObj != null &&
        <div style={{ marginBottom: '16px' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ fontSize: '14px', color: 'grey' }}>Current Remarks</div>
            <div style={{ fontSize: '14px', color: 'grey' }}>By: {currentRemarkObj.adminUserName}</div>
            <div style={{ fontSize: '14px', color: 'grey' }}>On: {formatDDMMYYYYDate(currentRemarkObj.createDate)}</div>
          </div>
          <div style={{
            color: 'grey',
            border: '1px solid lightgrey',
            borderRadius: '4px',
            padding: '8px'
          }}>{currentRemarkObj.remarks}</div>
        </div>
      }

      <TextFieldComponent
        label={getFieldLabel("remarks")}
        type="text"
        multiline={true}
        rows={4}
        field={{
          ...formik.getFieldProps("remarks"),
          onChange: formik.handleChange("remarks"),
        }}
        form={formik}
        fullWidth
        style={{ marginBottom: "16px" }}
      />

      <DropdownComponent
        label={getFieldLabel("experienceLevel")}
        field={{
          ...formik.getFieldProps("experienceLevel"),
          onChange: formik.handleChange("experienceLevel"),
        }}
        form={formik}
        options={experienceLevel}
        fullWidth
        style={{ marginBottom: "16px" }}
        selectStyle={{
          menuPortal: base => ({ ...base, zIndex: 9999 })
        }}
      />

      {formik.values.experienceLevel?.id === "EXPERIENCED" &&
        formik.values.experiences.map((experience, index) => (
          !experience.shouldDelete && (
            <div key={index}>
              <TextFieldComponent
                label={getExpFieldLabel('companyName', index)}
                type="text"
                field={{
                  ...formik.getFieldProps(`experiences[${index}].companyName`),
                  onChange: formik.handleChange(`experiences[${index}].companyName`),
                }}
                form={formik}
                fullWidth
                style={{ marginBottom: "16px" }}
                error={formik && formik.touched?.["experiences"]?.[index]?.["companyName"] && Boolean(formik.errors?.["experiences"]?.[index]?.["companyName"])}
                helperText={formik && formik.touched?.["experiences"]?.[index]?.["companyName"] && formik.errors?.["experiences"]?.[index]?.["companyName"]}
              />

              <TextFieldComponent
                label={getExpFieldLabel('jobTitle', index)}
                type="text"
                field={{
                  ...formik.getFieldProps(`experiences[${index}].jobTitle`),
                  onChange: formik.handleChange(`experiences[${index}].jobTitle`),
                }}
                form={formik}
                fullWidth
                style={{ marginBottom: "16px" }}
                error={formik && formik.touched?.["experiences"]?.[index]?.["jobTitle"] && Boolean(formik.errors?.["experiences"]?.[index]?.["jobTitle"])}
                helperText={formik && formik.touched?.["experiences"]?.[index]?.["jobTitle"] && formik.errors?.["experiences"]?.[index]?.["jobTitle"]}
              />

              <TextFieldComponent
                label={getExpFieldLabel('salary', index)}
                type="tel"
                inputmode="numeric"
                pattern="[0-9\s]{13,19}"
                field={{
                  ...formik.getFieldProps(`experiences[${index}].salary`),
                  onChange: formik.handleChange(`experiences[${index}].salary`),
                }}
                form={formik}
                fullWidth
                style={{ marginBottom: "16px" }}
              />

              <div style={{ marginBottom: '16px' }}>
                <TextFieldComponent
                  label={getExpFieldLabel('startDate', index)}
                  type="date"
                  field={{
                    ...formik.getFieldProps(`experiences[${index}].startDate`),
                    onChange: formik.handleChange(`experiences[${index}].startDate`),
                  }}
                  form={formik}
                  fullWidth
                  style={{ marginBottom: "16px" }}
                  error={formik && formik.touched?.["experiences"]?.[index]?.["startDate"] && Boolean(formik.errors?.["experiences"]?.[index]?.["startDate"])}
                  helperText={formik && formik.touched?.["experiences"]?.[index]?.["startDate"] && formik.errors?.["experiences"]?.[index]?.["startDate"]}
                />
              </div>

              <div style={{ marginBottom: '16px' }}>
                <TextFieldComponent
                  label={getExpFieldLabel('endDate', index)}
                  type="date"
                  field={{
                    ...formik.getFieldProps(`experiences[${index}].endDate`),
                    onChange: formik.handleChange(`experiences[${index}].endDate`),
                  }}
                  form={formik}
                  fullWidth
                  style={{ marginBottom: "16px" }}
                  error={formik && formik.touched?.["experiences"]?.[index]?.["endDate"] && Boolean(formik.errors?.["experiences"]?.[index]?.["endDate"])}
                  helperText={formik && formik.touched?.["experiences"]?.[index]?.["endDate"] && formik.errors?.["experiences"]?.[index]?.["endDate"]}
                />
              </div>

              <ButtonComponent
                type="button"
                label="Remove Experience"
                fullWidth
                onClick={() => handleRemoveExperience(experience, index)}
                style={{ marginBottom: "16px" }}
              />
            </div>
          )
        ))}

      {formik.values.experiences.every(isExperienceValid) && (
        <ButtonComponent
          type="button"
          label="Add Experience"
          fullWidth
          onClick={handleAddExperience}
          style={{ marginBottom: "16px" }}
        />
      )}


      <ButtonComponent
        type="submit"
        label={formik.isSubmitting ? "Submitting..." : "Submit"}
        fullWidth
        disabled={formik.isSubmitting}
      />
    </form>
  );
};

export default CandidateForm;