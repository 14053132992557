import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import TextFieldComponent from '../components/TextFieldComponent';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { isSuperUser } from '../redux/selectors/userSelector';
import ConfirmationDialog from '../components/ConfirmationDialog';
import { deleteJobRoleById, getJobRolesList } from '../redux/actions/jobActions';
import AddJobRole from '../models/AddJobRole';
import { ArrowBackIosNew } from '@mui/icons-material';
import HeaderComponent from '../components/HeaderComponent';
import PaginationComponent from '../components/PaginationComponent';


const ViewJobRoles = () => {

  const dispatch = useDispatch();
  const jobRoles = useSelector((state) => state.job.jobRoles);

  const totalRecords = jobRoles?.metadata?.totalRecords;
  const totalPages = jobRoles?.metadata?.totalPages;

  const [selectedJobRoleId, setSelectedJobRoleId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isJobRoleVisible, setJobRoleVisible] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);

  const [jobRoleDetail, setJobRoleDetail] = useState(null);
  const [isJobRoleDeleteModalOpen, setIsJobRoleDeleteModalOpen] = useState(false);

  const [queryParams, setQueryParams] = useState({
    pageNumber: 0, // default to the first page
    searchText: '',
    sort: ''
  });

  const handlePageChange = (event, page) => {
    handleConfirmationDialogClose();
    setJobRoleVisible(false);
    setQueryParams((prevParams) => ({ ...prevParams, pageNumber: page - 1 }));
  };


  const handleConfirmationDialogClose = () => {
    setIsJobRoleDeleteModalOpen(false);
  };

  const handleConfirmationDialog = () => {
    setIsJobRoleDeleteModalOpen(false);
    dispatch(deleteJobRoleById(selectedJobRoleId));
    setIsJobRoleDeleteModalOpen(false);
    setSelectedJobRoleId(null);
  };

  useEffect(() => {
    dispatch(getJobRolesList(queryParams));
  }, [dispatch, queryParams]);

  const handleSearch = (text) => {
    setSearchTerm(text);
    clearTimeout(typingTimeout); // Clear the previous timeout
    const timeout = setTimeout(() => {
      setQueryParams((prevParams) => ({ ...prevParams, searchText: text, pageNumber: 0 }));
    }, 700);
    setTypingTimeout(timeout);
  };


  const handleAddJobRoleClick = () => {
    setJobRoleDetail(null);
    setJobRoleVisible(!isJobRoleVisible);
  };


  const handleEditClick = (jobRole) => {
    setJobRoleVisible(false);

    const mappedJobRole = {
      ...jobRole,
      department: jobRole.department && { id: jobRole.department, name: jobRole.department },
      similarRole1: jobRole.similarRole1 && { id: jobRole.similarRole1, name: jobRole.similarRole1 },
      similarRole2: jobRole.similarRole2 && { id: jobRole.similarRole2, name: jobRole.similarRole2 },
      similarRole3: jobRole.similarRole3 && { id: jobRole.similarRole3, name: jobRole.similarRole3 }
    };

    setJobRoleDetail(mappedJobRole);
    setJobRoleVisible(true);
  };

  const handleDeleteClick = (id) => {
    setJobRoleVisible(false);
    setSelectedJobRoleId(id);
    setIsJobRoleDeleteModalOpen(true);
  };

  const superUser = useSelector(isSuperUser);

  return (
    <>
      <HeaderComponent name="Job Roles" totalRecords={totalRecords} />
      <Grid container spacing={3}>
        {/* Left side: Admins Table */}
        <Grid size={{ xs: 12, md: isJobRoleVisible ? 8 : 12 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {/* Search Input on the Left Side */}
            <TextFieldComponent
              label="Search"
              type="search"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              style={{ width: '350px' }}
            />

            {/* Add User Button on the Right Side */}
            {superUser && <Tooltip title="Add User">
              <Button style={{ whiteSpace: 'nowrap' }} variant="contained" color="primary" onClick={handleAddJobRoleClick}>
                {/* <AddIcon style={{ marginRight: '8px' }} /> */}
                Add Job Role
              </Button>
            </Tooltip>}
          </div>

          <div style={{ margin: '16px 0' }} />

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={styles.textBold}>Position</TableCell>
                  <TableCell style={styles.textBold}>Description</TableCell>
                  <TableCell style={styles.textBold}>Department</TableCell>
                  <TableCell style={styles.textBold}>Similar Role</TableCell>
                  <TableCell style={styles.textBold}>Approved</TableCell>
                  {superUser && <TableCell style={styles.textBold}>Edit</TableCell>}
                  {superUser && <TableCell style={styles.textBold}>Delete</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {jobRoles?.data?.map((jobRole, index) => (
                  <TableRow key={index}>
                    <TableCell><div style={{ position: 'relative' }} >
                      <span style={{ position: 'absolute', top: '-10px', left: '-10px', padding: '3px', borderRadius: '4px', backgroundColor: !jobRole.approved && 'orange' }} />
                    </div>{jobRole?.name}</TableCell>
                    <TableCell>{jobRole?.description}</TableCell>
                    <TableCell>{jobRole?.department}</TableCell>
                    <TableCell>
                      {[jobRole?.similarRole1, jobRole?.similarRole2, jobRole?.similarRole3]
                        .filter(role => !!role)
                        .join(", ")}
                    </TableCell>
                    <TableCell>{jobRole.approved ? "Yes" : "No"}</TableCell>

                    {superUser && <TableCell>
                      <IconButton onClick={() => handleEditClick(jobRole)}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>}
                    {superUser && <TableCell>
                      <IconButton onClick={() => handleDeleteClick(jobRole.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container justifyContent="flex-end" style={{ marginTop: '16px' }}>
            <PaginationComponent
              pageNumber={queryParams.pageNumber + 1}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </Grid>
        </Grid>

        {/* Right side: Add User Button */}
        {isJobRoleVisible && (
          <Grid size={{ xs: 12, md: 4 }} style={{ paddingTop: '16px', paddingLeft: '16px', background: 'white', borderRadius: '5px 0', boxShadow: '-5px -5px 5px rgb(0 0 0 / 0.2)' }}>
            <Button variant='contained' startIcon={<ArrowBackIosNew />} onClick={handleAddJobRoleClick} style={{ marginBottom: '16px' }}>
              Back
            </Button>
            <AddJobRole onClose={() => setJobRoleVisible(false)} jobRoleDetail={jobRoleDetail} />
          </Grid>
        )}

        {isJobRoleDeleteModalOpen === true && (
          <ConfirmationDialog
            open={true}
            onClose={handleConfirmationDialogClose}
            onConfirm={handleConfirmationDialog}
            message="Do you want to Delete?"
          />
        )}
      </Grid>
    </>
  );
};

export default ViewJobRoles;

const styles = {
  textBold: {
    fontWeight: 'bold'
  }
}