import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { CircularProgress, IconButton, Box, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import CandidateForm from '../pages/CandidateForm';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { assignDocToNewUser, uploadResumeFile } from '../redux/service/userService';
import constants from '../helpers/constants.json';
import AddRemarksModal from './AddRemarksModal';

const NonAppUsersModal = ({ isOpen, onClose, nonAppUserFile, candidateQueryParams, viewProfile, editProfile }) => {
  const dispatch = useDispatch();

  const isNewCandidateCreated = useSelector((state) => state.candidate.newCandidateCreated);
  const newCandidateCreatedId = useSelector((state) => state.candidate.newCandidateCreatedId);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isParsingFailed, setIsParsingFailed] = useState(false);
  const [isUserExist, setIsUserExist] = useState(false);
  const [existingUserId, setExistingUserId] = useState(null);
  const [resumeParsedData, setResumeParsedData] = useState(null);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [isAddRemarksModalVisible, setIsAddRemarksModalVisible] = useState(false);
  const { docType } = constants;

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1000,
      marginTop: '2em'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '85vw',
      width: '90%',
      maxHeight: '90%',
      borderRadius: '8px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      background: '#fff',
      padding: '20px',
      overflow: 'auto',
    },
  };

  useEffect(() => {
    if (isNewCandidateCreated) {
      onClose();
      let payload = {};
      payload["userId"] = newCandidateCreatedId;
      payload["fileUrl"] = resumeParsedData["uploadUrl"];
      payload["docType"] = docType.cvResume;
      assignDocToNewUser(payload);
    }

  }, [dispatch, isNewCandidateCreated, onClose]);

  const onUploadProgress = event => {
    const percentCompleted = Math.round((event.loaded * 100) / event.total);
    setFileUploadProgress(percentCompleted);
  };

  const handleTryAgain = () => {
    setIsFileUploaded(false);
    setFileUploadProgress(0);
    setIsParsingFailed(false);
    uploadAndParseResume(nonAppUserFile, onUploadProgress);
  }

  async function uploadAndParseResume() {
    // upload and parse resume api call
    let res = await uploadResumeFile(nonAppUserFile, onUploadProgress);

    console.log(res);

    if (res.success) {
      const parsedResponse = res.data?.resumeParsed;
      const s3FileUrl = res.data?.fileUrl;
      // parse data structure
      const parsedData = {};
      parsedData["name"] = parsedResponse?.name;
      parsedData["mobileNumber"] = parsedResponse?.mobileNumber;
      parsedData["dob"] = parsedResponse?.dob;
      parsedData["description"] = parsedResponse?.summary;
      parsedData["location"] = parsedResponse?.location;
      parsedData["highestEducation"] = parsedResponse?.education?.education;
      parsedData["education"] = parsedResponse?.education;
      if (parsedResponse?.experience?.length > 0) {
        parsedData["experienceLevel"] = "EXPERIENCED";
        parsedData["experience"] = parsedResponse?.experience;
      } else {
        parsedData["experienceLevel"] = "FRESHER";
      }

      parsedData["uploadUrl"] = s3FileUrl;

      // if file type is pdf then we use different component for preview, and it can directly preview using file instance
      // for other filetype we use s3 url
      if (nonAppUserFile.type.includes("pdf")) {
        parsedData["fileUrl"] = URL.createObjectURL(nonAppUserFile);
        parsedData["isPDF"] = true;
      } else {
        parsedData["fileUrl"] = s3FileUrl;
        parsedData["isPDF"] = false;
      }
      parsedData["fileType"] = nonAppUserFile.type;
      setResumeParsedData(parsedData);

      // if userId is there in response then user already exist with same mobile number
      if (res.data?.userId) {
        setIsUserExist(true);
        setExistingUserId(res.data?.userId);
      }

    } else {
      setIsParsingFailed(true);
    }

    setIsFileUploaded(true);
  }

  // upload resume file to backend and parse the resume when component mounts
  useEffect(() => {
    uploadAndParseResume();
  }, [])

  const handleAddRemarks = () => {
    setIsAddRemarksModalVisible(true);
  }

  const handleCloseAddRemarksModal = () => {
    setIsAddRemarksModalVisible(false);
  }

  const CircularProgressWithLabel = (props) => {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Candidate Users"
      style={customStyles}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
        <h2>Candidate User</h2>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>

      {isUserExist &&
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ marginTop: '8px', marginBottom: '8px', fontWeight: 'bold' }}>{nonAppUserFile.name}</div>
          <div style={{ marginBottom: '16px' }}>User with the same Mobile Number({resumeParsedData?.mobileNumber}) already <b>Exist</b></div>
          <div style={{ display: 'flex', marginBottom: '16px', columnGap: '12px' }}>
            <Button variant="contained" color="primary" onClick={() => { handleAddRemarks() }}>
              Add Remarks
            </Button>
            <Button variant="contained" color="primary" onClick={() => { viewProfile(existingUserId) }}>
              View Profile
            </Button>
            <Button variant="contained" color="primary" onClick={() => { editProfile(existingUserId) }}>
              Edit Profile
            </Button>
          </div>
        </div>
      }

      {!isFileUploaded &&
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {fileUploadProgress < 100 ?
            <>
              <div>Resume file is being <b>Uploaded</b></div>
              <div style={{ marginTop: '12px' }}>
                <CircularProgressWithLabel value={fileUploadProgress} />
              </div>
            </>
            :
            <>
              <div>Resume is being <b>Parsed</b></div>
              <div style={{ marginTop: '12px' }}>
                <CircularProgress />
              </div>
            </>
          }
        </div>
      }

      {isParsingFailed &&
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ marginBottom: '16px' }}>Resume parsing <b>Failed</b></div>
          <Button style={{ marginLeft: '8px', whiteSpace: 'nowrap' }} variant="contained" color="primary" onClick={handleTryAgain}>
            Try Again
          </Button>
        </div>
      }

      {isFileUploaded && !isParsingFailed && !isUserExist &&
        <Grid container>
          <Grid size={{ xs: 8 }}>
            <div style={{ marginTop: '8px', marginBottom: '8px', fontWeight: 'bold' }}>{nonAppUserFile.name}</div>
            <div style={{ width: '100%', height: '100%' }}>
              {resumeParsedData?.isPDF ?
                <object data={resumeParsedData.fileUrl} type="application/pdf" width="100%" height="100%">
                  <p>File Preview not available</p>
                </object>
                :
                <DocViewer
                  documents={[
                    {
                      uri: resumeParsedData.fileUrl,
                      fileType: resumeParsedData.fileType,
                      // fileName: nonAppUserFile.name
                    }
                  ]}
                  pluginRenderers={DocViewerRenderers}
                />
              }
            </div>
          </Grid>
          <Grid size={{ xs: 4, md: 4 }} style={{ background: 'white', padding: '0px 12px' }}>
            <div style={{ margin: "16px 0px", fontWeight: 'bold', color: 'green' }}>Create Form For {resumeParsedData?.name}</div>
            <CandidateForm candidateDetail={resumeParsedData} resumeParsedData={true} candidateQueryParams={candidateQueryParams} />
          </Grid>
        </Grid>
      }

      {isAddRemarksModalVisible &&
        <AddRemarksModal
          open={true}
          onClose={handleCloseAddRemarksModal}
          isCandidate={true}
          userId={existingUserId}
        />
      }


    </ReactModal>
  );
};

export default NonAppUsersModal;
